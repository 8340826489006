import Vue from 'vue';

/**
 * titleOptions
*/
export const titleList = [
  { value: 'Dr.', text: Vue.prototype.$t('metaData.fields.naturalUser.title.options.dr') },
  { value: 'Prof.', text: Vue.prototype.$t('metaData.fields.naturalUser.title.options.prof') },
];

/**
 * sexOptions
*/
export const sexList = [
  { value: 'female', text: Vue.prototype.$t('metaData.fields.naturalUser.sex.options.female') },
  { value: 'male', text: Vue.prototype.$t('metaData.fields.naturalUser.sex.options.male') },
  { value: 'diverse', text: Vue.prototype.$t('metaData.fields.naturalUser.sex.options.diverse') },
];


export default {titleList, sexList};
