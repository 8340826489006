var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"address-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.address.label')},on:{"loading-update":_vm.onLoadUpdate,"error-update":_vm.onErrorUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.street.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.street.placeholder'),"error":checkIfExists('errors.naturalUser.address.street')
            ? niceErrorMessage(errors.naturalUser.address.street)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.address.street),callback:function ($$v) {_vm.$set(naturalUser.address, "street", $$v)},expression:"naturalUser.address.street"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.streetNo.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.streetNo.placeholder'),"error":checkIfExists('errors.naturalUser.address.streetNo')
            ? niceErrorMessage(errors.naturalUser.address.streetNo)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.address.streetNo),callback:function ($$v) {_vm.$set(naturalUser.address, "streetNo", $$v)},expression:"naturalUser.address.streetNo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.streetAdditional.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.streetAdditional.placeholder'),"error":checkIfExists('errors.naturalUser.address.streetAdditional')
            ? niceErrorMessage(errors.naturalUser.address.streetAdditional)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.address.streetAdditional),callback:function ($$v) {_vm.$set(naturalUser.address, "streetAdditional", $$v)},expression:"naturalUser.address.streetAdditional"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.postalCode.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.postalCode.placeholder'),"error":checkIfExists('errors.naturalUser.address.postalCode')
            ? niceErrorMessage(errors.naturalUser.address.postalCode)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.address.postalCode),callback:function ($$v) {_vm.$set(naturalUser.address, "postalCode", $$v)},expression:"naturalUser.address.postalCode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.city.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.city.placeholder'),"error":checkIfExists('errors.naturalUser.address.city') ? niceErrorMessage(errors.naturalUser.address.city) : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.address.city),callback:function ($$v) {_vm.$set(naturalUser.address, "city", $$v)},expression:"naturalUser.address.city"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('AutoCompleteComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.address.country.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.country.label'),"optionsList":_vm.countryOptions,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.address.country')
            ? niceErrorMessage(errors.naturalUser.address.country)
            : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country"},model:{value:(naturalUser.address.country),callback:function ($$v) {_vm.$set(naturalUser.address, "country", $$v)},expression:"naturalUser.address.country"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }