<template>
  <div class="main-section-content wrapper">
    <Stepper :loading="loading" />
    <main :class="$route.title" :key="$i18n.language">
      <slot />
    </main>
  </div>
</template>

<script>
import Stepper from '@/components/common/Stepper.vue';

export default {
  name: 'layout-onboarding',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    Stepper,
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/colors';

.layout-onboarding {
  display: flex;
}

::v-deep .v-radio .v-label {
  color: color('text', 'regular');
};

::v-deep .v-text-field .v-label,
::v-deep .v-text-field--outlined .v-label {
  transform: translateY(-24px) scale(0.75) !important;
  background: color("background", "white");
  padding-left: 3px;
  padding-right: 3px;
}
</style>
