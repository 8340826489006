var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"taxes-view mb-8",attrs:{"limitedFields":_vm.limitedFields,"onNext":_vm.onNext,"nextLabel":_vm.$t('nextStep'),"onBack":_vm.onBack,"backLabel":_vm.$t('prevStep')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
var loading = ref.loading;
return [_c('v-card',{staticClass:"mt-5 mb-8 pa-4",attrs:{"elevation":"2","loading":loading}},[_c('v-card-title',{staticClass:"mb-2"},[_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('taxes.title')))])]),_c('v-card-text',{staticClass:"body-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('taxes.intro'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('AutoCompleteComponent',{attrs:{"label":((_vm.$t('metaData.fields.naturalUser.taxableCountry.label')) + "*"),"optionsList":_vm.countriesOptions,"onSelect":function (e) {
                naturalUser.taxId = null;
                if (checkIfExists('errors.naturalUser.taxableCountry')) {
                  delete errors.naturalUser.taxableCountry;
                }
              },"error":checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country"},model:{value:(naturalUser.taxableCountry),callback:function ($$v) {_vm.$set(naturalUser, "taxableCountry", $$v)},expression:"naturalUser.taxableCountry"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.taxId.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.taxId.placeholder'),"tooltip":_vm.$t('metaData.fields.naturalUser.taxId.tooltip'),"error":checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : '',"nextBtnClicked":nextBtnClicked,"inputType":"text"},on:{"blur":function () {
                if (checkIfExists('errors.naturalUser.taxId')) {
                  delete errors.naturalUser.taxId;
                }
              }},model:{value:(naturalUser.taxId),callback:function ($$v) {_vm.$set(naturalUser, "taxId", $$v)},expression:"naturalUser.taxId"}})],1)],1),_c('v-row',[_c('v-radio-group',{staticClass:"ma-2",attrs:{"color":checkIfExists('errors.naturalUser.isUSTaxed') ? 'error' : '',"hide-details":"auto","error-messages":checkIfExists('errors.naturalUser.isUSTaxed') ? niceErrorMessage(errors.naturalUser.isUSTaxed) : []},on:{"click":onSave},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"body-1 text--primary pl-1 pb-4 d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t('metaData.fields.naturalUser.isUSTaxed.label'))+" "),_c('v-tooltip',{staticClass:"pa-0 ma-0",attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(_vm.$t('metaData.fields.naturalUser.isUSTaxed.tooltip')))])])],1),_c('v-radio',{staticClass:"pb-2",attrs:{"value":false},on:{"click":onSave},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('metaData.fields.naturalUser.isUSTaxed.labelRadioOption1'))}})]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"value":true},on:{"click":onSave},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('metaData.fields.naturalUser.isUSTaxed.labelRadioOption2'))}})]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(naturalUser.isUSTaxed),callback:function ($$v) {_vm.$set(naturalUser, "isUSTaxed", $$v)},expression:"naturalUser.isUSTaxed"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }