<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
    <path
      d="M195.488 78.2819C206.421 104.502 195.817 169.874 133.5 166.002C104.517 164.201 114.044 136.544 87 121.502C70.8808 112.537 24.4933 123.874 13.2327 107.126C3.50994 92.6721 -0.211686 75.0813 2.68895 62.0146C9.21204 32.6289 34.4131 6.17552 79.5408 35.5574C124.668 64.9392 166.462 8.67191 195.488 78.2819Z"
      fill="#D4D6DB"
    />
    <path
      d="M71.846 76.7847C71.846 76.7847 64.2088 46.4441 46.0888 41.6453C27.9688 36.8464 5.92295 60.1206 10.6485 70.8162C14.6886 79.9605 37.4768 62.8735 37.4768 62.8735C37.4768 62.8735 29.3163 75.7246 36.0544 80.2229C42.4015 84.46 55.6151 71.858 55.6151 71.858L71.846 76.7847Z"
      fill="white"
    />
    <path
      d="M125.855 94.8334C125.855 94.8334 150.201 75.1803 167.566 82.2392C184.93 89.2981 188.553 121.15 178.346 126.855C169.619 131.733 161.684 104.38 161.684 104.38C161.684 104.38 160.478 119.555 152.388 119.098C144.769 118.668 141.787 100.655 141.787 100.655L125.855 94.8334Z"
      fill="white"
    />
    <path
      d="M145.493 84.6627C149.328 110.622 130.975 134.717 104.458 138.499C77.9593 142.283 53.3601 124.301 49.5061 98.3417C45.6713 72.3999 64.0434 48.2871 90.5419 44.5045C117.059 40.7218 141.659 58.7035 145.493 84.6627Z"
      fill="#A1D4CA"
    />
    <path
      d="M145.493 84.6627C149.328 110.622 130.975 134.717 104.458 138.499C77.9593 142.283 53.3601 124.301 49.5061 98.3417C45.6713 72.3999 64.0434 48.2871 90.5419 44.5045C117.059 40.7218 141.659 58.7035 145.493 84.6627Z"
      fill="#27304D"
    />
    <path
      d="M133.624 86.8159C136.471 106.48 122.844 124.754 103.168 127.624C83.4925 130.477 65.2222 116.856 62.3757 97.1917C59.5299 77.51 73.1569 59.2365 92.8318 56.3839C112.507 53.5133 130.76 67.1341 133.624 86.8159Z"
      fill="#858DA7"
    />
    <path
      d="M44.9773 161.952C46.083 161.579 47.0467 160.786 47.606 159.658L56.9631 140.804C58.0824 138.549 57.1616 135.814 54.9064 134.695C52.6515 133.575 49.9159 134.496 48.7966 136.751L39.4395 155.605C38.3203 157.86 39.241 160.595 41.4962 161.714C42.6238 162.274 43.8716 162.324 44.9773 161.952Z"
      fill="#212121"
    />
    <path
      d="M71.7101 143.782C69.4989 142.684 66.8163 143.587 65.7187 145.799L48.0774 181.344C46.9798 183.556 47.8828 186.238 50.0943 187.336C51.2 187.885 52.4235 187.933 53.5078 187.568C54.5921 187.203 55.5372 186.425 56.0856 185.319L73.7267 149.774C74.8246 147.562 73.9217 144.88 71.7101 143.782Z"
      fill="#212121"
    />
    <path
      d="M82.9996 153.726L73.6427 172.579C72.5234 174.835 73.4441 177.57 75.6994 178.689C76.827 179.249 78.0747 179.298 79.1802 178.926C80.2856 178.554 81.2497 177.76 81.8089 176.632L91.1662 157.779C92.2855 155.524 91.3647 152.789 89.1095 151.669C86.8546 150.55 84.1189 151.471 82.9996 153.726Z"
      fill="#212121"
    />
    <path
      d="M108.054 82.5517V74.2251L91.0578 68.7363L86.7541 71.8861V89.7245L80.6729 92.7183L76.4004 95.8681L80.7041 93.7475L86.7541 90.7536L86.9101 90.6912L86.7541 90.7536V105.941L98.1682 115.546L102.472 112.397V99.7975L98.1682 102.947L102.472 99.7975L96.6713 93.1549V83.4249L103.782 85.7327V99.7975V111.461L108.085 108.311V87.1048L114.697 89.2567V109.091L119 105.941V86.0757L108.054 82.5517ZM98.1682 108.779L91.0578 102.791V94.7766L92.3676 96.2735V101.856L98.1682 106.721V108.779ZM103.75 81.1795L96.6401 78.8718L92.3364 82.0215V88.2275L91.5879 87.3543L91.0266 87.635V73.2895L103.75 77.406V81.1795Z"
      fill="white"
    />
    <path d="M80.6729 105.349V93.748L76.4004 95.8687V108.468L80.6729 105.349Z" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'FinalExpIcon',
  props: {
    color: {
      type: String,
      default: '#8BC8BC',
    },
  },
};
</script>
<style lang="scss">
@import '@/style/colors';
</style>