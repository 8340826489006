var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terms-and-conditions-view mb-8"},[_c('v-card',{staticClass:"mt-5 mb-8 pa-4",attrs:{"elevation":"2","loading":_vm.loading}},[_c('v-card-title',{staticClass:"mb-2"},[_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t('toc.headline'))+" ")])]),_c('v-card-text',{staticClass:"body-1 text--primary toc.label pb-6"},[_vm._v(" "+_vm._s(_vm.$t('toc.label'))+" ")]),(_vm.documents.length > 0)?_vm._l((_vm.documents),function(document,index){return _c('div',{key:("document" + index),staticClass:"terms-and-condition"},[(document.group)?[_c('div',{staticClass:"pb-8"},[_c('v-card-text',{staticClass:"subtitle-1 pb-0"},[_vm._v(_vm._s(_vm.$t(document.group.description)))]),_c('span',{staticClass:"subtitle-2 ma-4 pt-0 pb-0 grey-700--text"},[_vm._v(_vm._s(_vm.$t(document.group.title)))]),_vm._l((document.group.documents),function(term,j){return _c('v-row',{key:("term" + j),class:("term" + j + " ma-0")},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(!term.hide)?_c('v-checkbox',{staticClass:"ma-0",attrs:{"id":("checkbox-" + index + j),"hide-details":"auto"},on:{"change":_vm.onTermsClick},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-card-text',{staticClass:"body-2 pl-2 pa-0 ma-0 text-sm-justify",class:{ 'error--text': !_vm.checkboxs[("checkbox-" + index + j)] && _vm.errors.length > 0 },domProps:{"innerHTML":_vm._s(
                        _vm.$t(term.text, {
                          download: ("<a target='_blank' class='primary--text' href='" + (_vm.$t(term.path, {
                            language: _vm.language,
                          })) + "'\n                        >" + (_vm.$t(term.label)) + "</a>"),
                        })
                      )},on:{"click":function($event){$event.stopPropagation();}}})]},proxy:true}],null,true),model:{value:(_vm.checkboxs[("checkbox-" + index + j)]),callback:function ($$v) {_vm.$set(_vm.checkboxs, ("checkbox-" + index + j), $$v)},expression:"checkboxs[`checkbox-${index}${j}`]"}}):_vm._e()],1)],1)})],2)]:_vm._e(),(document.label)?[(!_vm.term.hide)?_c('v-checkbox',{staticClass:"ma-0",attrs:{"id":("checkbox-" + index),"hide-details":"auto"},on:{"change":_vm.onTermsClick},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-card-text',{staticClass:"body-2 pl-2 pa-0 ma-0",class:{ 'error--text': !_vm.checkboxs[("checkbox-" + index)] && _vm.errors.length > 0 },domProps:{"innerHTML":_vm._s(
                  _vm.$t(document.text, {
                    download: ("<a target='_blank' href='" + (_vm.$t(document.path, {
                      language: _vm.language,
                    })) + "'\n                      >" + (_vm.$t(document.label)) + "</a>"),
                  })
                )}})]},proxy:true}],null,true),model:{value:(_vm.checkboxs[("checkbox-" + index)]),callback:function ($$v) {_vm.$set(_vm.checkboxs, ("checkbox-" + index), $$v)},expression:"checkboxs[`checkbox-${index}`]"}}):_vm._e()]:_vm._e()],2)}):_vm._e(),(_vm.showMigrationMessage)?[_c('v-row',{staticClass:"migration-alert body-1 primary--text ma-0 pa-0 align-center"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.$t('toc.migration'))+" ")])],1)]:_vm._e(),(_vm.errors.length > 0)?[_c('v-row',{staticClass:"ma-0 pa-0 pt-0 error--text"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"dark":"","color":"error"}},[_vm._v("error")])],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._l((_vm.errors),function(error){return [_c('p',{key:error,staticClass:"ma-0"},[_vm._v(_vm._s(error))])]})],2)],1)]:_vm._e()],2),_c('v-row',{staticClass:"ma-0"},[_c('BrandedButton',{attrs:{"title":_vm.$t('prevStep'),"color":"primary","type":"tertiary","text":"","small":"","disabled":_vm.loading,"onClick":_vm.onBack,"icon-left":"chevron-left"}}),_c('v-spacer'),_c('BrandedButton',{attrs:{"title":_vm.$t('nextStep'),"color":"accent","type":"primary","small":"","icon-right":"chevron-right","onClick":_vm.onSave,"loading":_vm.loading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }