<template>
  <EditComponent
    class="taxes-view mb-8"
    v-bind:limitedFields="limitedFields"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked, loading }"
    :onNext="onNext"
    :nextLabel="$t('nextStep')"
    :onBack="onBack"
    :backLabel="$t('prevStep')"
  >
    <v-card class="mt-5 mb-8 pa-4" elevation="2" :loading="loading">
      <v-card-title class="mb-2"
        ><span class="h5">{{ $t('taxes.title') }}</span></v-card-title
      >
      <v-card-text class="body-1 text--primary">
        {{ $t('taxes.intro') }}
      </v-card-text>
      <v-card-text>
        <!--<div class="subtitle-1 text--primary mb-4">{{ $t('taxes.taxResidenceDescription') }}</div>-->
        <v-row>
          <v-col cols="12" sm="6">
            <AutoCompleteComponent
              v-model="naturalUser.taxableCountry"
              :label="`${$t('metaData.fields.naturalUser.taxableCountry.label')}*`"
              :optionsList="countriesOptions"
              :onSelect="
                (e) => {
                  naturalUser.taxId = null;
                  if (checkIfExists('errors.naturalUser.taxableCountry')) {
                    delete errors.naturalUser.taxableCountry;
                  }
                }
              "
              :error="
                checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : ''
              "
              :nextBtnClicked="nextBtnClicked"
              valueKey="code"
              valueLabel="country"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <InputComponent
              v-model="naturalUser.taxId"
              :label="$t('metaData.fields.naturalUser.taxId.label')"
              :placeholder="$t('metaData.fields.naturalUser.taxId.placeholder')"
              :tooltip="$t('metaData.fields.naturalUser.taxId.tooltip')"
              :error="checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : ''"
              :nextBtnClicked="nextBtnClicked"
              inputType="text"
              @blur="
                () => {
                  if (checkIfExists('errors.naturalUser.taxId')) {
                    delete errors.naturalUser.taxId;
                  }
                }
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-radio-group
            class="ma-2"
            v-model="naturalUser.isUSTaxed"
            :color="checkIfExists('errors.naturalUser.isUSTaxed') ? 'error' : ''"
            hide-details="auto"
            :error-messages="
              checkIfExists('errors.naturalUser.isUSTaxed') ? niceErrorMessage(errors.naturalUser.isUSTaxed) : []
            "
            @click="onSave"
          >
            <template v-slot:label>
              <div class="body-1 text--primary pl-1 pb-4 d-flex align-center">
                {{ $t('metaData.fields.naturalUser.isUSTaxed.label') }}
                <v-tooltip top class="pa-0 ma-0" max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                      <v-icon>info</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">{{ $t('metaData.fields.naturalUser.isUSTaxed.tooltip') }}</span>
                </v-tooltip>
              </div>
              <v-radio :value="false" @click="onSave" class="pb-2">
                <template v-slot:label>
                  <div v-html="$t('metaData.fields.naturalUser.isUSTaxed.labelRadioOption1')"></div>
                </template>
              </v-radio>
              <v-radio :value="true" @click="onSave">
                <template v-slot:label>
                  <div v-html="$t('metaData.fields.naturalUser.isUSTaxed.labelRadioOption2')"></div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-row>
      </v-card-text>
    </v-card>
  </EditComponent>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import { countryOptions, blacklistedCountries } from '@/enum/countries';
import BrandedButton from '@/components/reusable/BrandedButton';

export default {
  name: 'taxes-view',
  components: {
    EditComponent,
    InputComponent,
    AutoCompleteComponent,
  },
  props: {
    limitedFields: {
      type: String,
      default: 'taxes',
    },
  },

  data() {
    const defaultCountries = ['DE', 'AT', 'NL', 'CH', 'FR', 'IT', 'DK'];

    const countriesOptions = countryOptions()
      .filter((item) => {
        return !blacklistedCountries.includes(item.code) && !defaultCountries.includes(item.code);
      })
      .map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      }));

    const countriesOptionsDefault = defaultCountries.map((item) => {
      const country = countryOptions().find((countryObj) => {
        return countryObj.code == item;
      });

      return {
        value: country.code,
        text: country.country,
      };
    });

    return {
      countriesOptions: [...countriesOptionsDefault, ...countriesOptions],
    };
  },
  computed: {
    ...mapGetters(['brandSchema']),
    brandName() {
      return this.brandSchema.name;
    },
    getIsUSTaxed: {
      get(value) {
        return !value.$children[0].naturalUser.hasOwnProperty('isUSTaxed')
          ? false
          : !value.$children[0].naturalUser.isUSTaxed;
      },
      set(value) {
        this.$children[0].errors = null;
        this.$children[0].naturalUser.isUSTaxed = !value;
      },
    },
  },
  async mounted() {
    !this.$children[0].naturalUser.hasOwnProperty('isUSTaxed')
      ? (this.$children[0].naturalUser.isUSTaxed = true)
      : null;
    this.$store.commit('setLoaded', { loaded: true });
  },

  methods: {
    onNext() {
      this.$router.push('/onboarding/toc');
    },
    onBack() {
      this.$router.push('/onboarding/personal-information');
    },
  },
};
</script>

<style scoped lang="scss">
// @import "@/style/base.scss";
</style>

