<template>
  <EditComponent
    class="personal-information-view mb-8"
    v-bind:limitedFields="limitedFields"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked, loading }"
    :onNext="onNext"
    :nextLabel="$t('nextStep')"
    :onBack="onBackToHome"
    :backLabel="$t('backHome')"
  >
    <v-card class="mt-5 mb-8 pa-4" elevation="2" :loading="loading">
      <v-card-title class="mb-2"
        ><span class="h5">{{ $t('metaData.title') }}</span></v-card-title
      >
      <v-card-text class="body-1 text--primary">
        {{ $t('metaData.intro') }}
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <InputComponent
              v-model="naturalUser.firstName"
              :label="`${$t('metaData.fields.naturalUser.firstName.label')}*`"
              :placeholder="$t('metaData.fields.naturalUser.firstName.label')"
              :error="
                checkIfExists('errors.naturalUser.firstName') ? niceErrorMessage(errors.naturalUser.firstName) : ''
              "
              :tooltip="isIdentified ? $t('metaData.fields.naturalUser.firstName.tooltip') : null"
              :nextBtnClicked="nextBtnClicked"
              inputType="text"
              :disabled="isFieldDisabledAfterIdent(isIdentified, naturalUser.firstName)"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <InputComponent
              v-model="naturalUser.lastName"
              :label="`${$t('metaData.fields.naturalUser.lastName.label')}*`"
              :placeholder="$t('metaData.fields.naturalUser.lastName.placeholder')"
              :error="checkIfExists('errors.naturalUser.lastName') ? niceErrorMessage(errors.naturalUser.lastName) : ''"
              :tooltip="isIdentified ? $t('metaData.fields.naturalUser.lastName.tooltip') : null"
              :nextBtnClicked="nextBtnClicked"
              inputType="text"
              :disabled="isFieldDisabledAfterIdent(isIdentified, naturalUser.lastName)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <AutoCompleteComponent
              v-model="naturalUser.title"
              :label="`${$t('metaData.fields.naturalUser.title.label')}`"
              :optionsList="titleList"
              :onSelect="null"
              :placeholder="$t('metaData.fields.naturalUser.title.placeholder')"
              :error="checkIfExists('errors.naturalUser.title') ? niceErrorMessage(errors.naturalUser.title) : ''"
              :nextBtnClicked="nextBtnClicked"
              valueKey="value"
              valueLabel="text"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6">
            <InputComponent
              v-model="naturalUser.birth.dateOfBirth"
              :label="`${$t('metaData.fields.naturalUser.birth.dateOfBirth.label')}*`"
              :error="
                checkIfExists('errors.naturalUser.birth.dateOfBirth')
                  ? dobValidation(naturalUser.birth.dateOfBirth) ||
                    niceErrorMessage(errors.naturalUser.birth.dateOfBirth)
                  : ''
              "
              :placeholder="$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder')"
              :onChange="null"
              :nextBtnClicked="nextBtnClicked"
              inputType="date"
              :disabled="isFieldDisabledAfterIdent(isIdentified, naturalUser.birth.dateOfBirth)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <AutoCompleteComponent
              v-model="naturalUser.citizenship"
              :label="`${$t('metaData.fields.naturalUser.citizenship.label')}*`"
              :optionsList="nationalityOptions"
              :onSelect="
                (e) => {
                  if (checkIfExists('errors.naturalUser.citizenship')) {
                    delete errors.naturalUser.citizenship;
                  }
                }
              "
              :error="
                checkIfExists('errors.naturalUser.citizenship')
                  ? niceErrorMessage(errors.naturalUser.citizenship)
                  : ''
              "
              :nextBtnClicked="nextBtnClicked"
              valueKey="code"
              valueLabel="country"
              :disabled="isFieldDisabledAfterIdent(isIdentified, naturalUser.citizenship)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-radio-group
            class="ma-2"
            v-model="naturalUser.isPEP"
            color=""
            hide-details="auto"
            :messages="
              !!naturalUser.isPEP
                ? [
                    $t('metaData.fields.naturalUser.isPEP.error.default', {
                      phone: brandSchema.supportPhone,
                      email: brandSchema.supportEmail,
                    }),
                  ]
                : []
            "
          >
            <template v-slot:label>
              <div class="body-1 text--primary pl-1 pb-4 d-flex align-center pr-0">
                {{ $t('metaData.fields.naturalUser.isPEP.label') }}
                <v-tooltip top class="pa-0 ma-0" max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                      <v-icon>info</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">{{ $t('metaData.fields.naturalUser.isPEP.tooltip') }}</span>
                </v-tooltip>
              </div>
              <v-radio :value="false" @click="onSave" class="pb-2">
                <template v-slot:label>
                  <div v-html="$t('metaData.fields.naturalUser.isPEP.labelRadioOption1')"></div>
                </template>
              </v-radio>
              <v-spacer></v-spacer>
              <v-radio :value="true" @click="onSave">
                <template v-slot:label>
                  <div v-html="$t('metaData.fields.naturalUser.isPEP.labelRadioOption2')"></div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-row>
      </v-card-text>
    </v-card>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import { nationalityOptions } from '@/enum/countries';
import { sexList, titleList } from '@/enum/userOptions';
import dobValidation from '@/utils/validations/dobValidation';
import { mapGetters } from 'vuex';

export default {
  name: 'personalInformation',
  data() {
    const defaultCountries = ['DE', 'AT', 'NL', 'CH', 'FR', 'IT', 'DK'];

    const nationalityOptionsDefault = defaultCountries.map((item) => {
      const country = nationalityOptions().find((countryObj) => {
        return countryObj.code == item;
      });

      return (
        country && {
          value: country.code,
          text: country.country,
        }
      );
    });

    return {
      nationalityOptions: [
        ...nationalityOptionsDefault,
        ...nationalityOptions().map((option) => ({
          value: option.code,
          text: option.country,
        })),
      ],
      sexList,
      titleList,
    };
  },
  computed: {
    ...mapGetters(['isIdentified', 'brandSchema']),
    showEmail: function () {
      return this.$route.path.includes('/profile');
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'personalInformation',
    },
  },
  methods: {
    dobValidation,
    onNext() {
      this.$router.push('/onboarding/taxes');
    },
    onBackToHome() {
      window.location.href=this.brandSchema.url;
    },
    isFieldDisabledAfterIdent(isIdentified, value) {
      return isIdentified && !!value;
    },
  },
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  components: {
    AutoCompleteComponent,
    EditComponent,
    InputComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

