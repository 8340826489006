



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

interface Step {
  path: string;
  valid: boolean;
}
@Component({
  name: 'Stepper',
  computed: {
    ...mapGetters(['onboardingStatus', 'isIdentified']),
  },
})
export default class Stepper extends Vue {
  $route: any;
  $router: any;
  public isIdentified: any;
  public onboardingStatus: any;

  get onboardingSteps(): Step[] {
    return [
      {
        path: '/onboarding/personal-information',
        valid: this.onboardingStatus ? !!this.onboardingStatus.hasEnoughMetaData.personalInformation : false,
      },
      {
        path: '/onboarding/taxes',
        valid: this.onboardingStatus ? !!this.onboardingStatus.hasEnoughMetaData.taxes : false,
      },
      {
        path: '/onboarding/toc',
        valid: this.onboardingStatus ? !!this.onboardingStatus.hasAcceptLatestToC : false,
      },
      {
        path: '/onboarding/ident',
        valid: this.onboardingStatus ? !!this.onboardingStatus.hasEnoughMetaData.address && !!this.isIdentified : false,
      },
    ];
  }

  @Prop({ default: true, required: true }) private loading?: Boolean;

  onStepClick(path) {
    if (this.$route.path != path) {
      this.$router.push(path);
    }
  }
}
