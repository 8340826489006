<template>
  <div class="onboarding-show-view">
    <p class="error">{{ info }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'onboarding-show-view',
  data() {
    return {
      info: null,
    };
  },
  async mounted() {
    const status = await this.$store.dispatch('fetchOnboardingStatus');

    if (!status.valid) {
      if (!status.components.hasEnoughMetaData.personalInformation) {
        return this.$router.push('/onboarding/personal-information');
      } else if (!status.components.hasEnoughMetaData.taxes) {
        return this.$router.push('/onboarding/taxes');
      } else if (!status.components.hasAcceptLatestToC && this.isApplicable('hasAcceptLatestToC', this.validationType)) {
        return this.$router.push('/onboarding/toc');
      } else if (!status.components.isIdentified && this.isApplicable('isIdentified', this.validationType)) {
        return this.$router.push('/onboarding/ident');
      } else if (status.components.isIdentified && !status.components.hasEnoughMetaData.address) {
        return this.$router.push('/onboarding/ident');
      } else if(!status.components.isPEPVerified) {
        return this.$router.push('/onboarding/final');
      }
    } else if (this.getSuccessUrl && this.getSuccessUrl.includes('https://')) {
      const targetUrl = this.getSuccessUrl;
      this.$store.commit('setSuccessUrl', null);
      window.location.href = targetUrl;
    } else {
      this.$router.push('/onboarding/final');
    }

    return null;
  },
  computed: {
    ...mapGetters(['validationType', 'isOnboarded', 'getSuccessUrl']),
    baseDomain: () => {
      return process.env.VUE_APP_BACKEND_BASE_DOMAIN;
    },
  },
  methods: {
    isApplicable(componentName, type) {
      const components = this.$store.getters.getOnboardingComponents;
      if (components && components[componentName]) {
        return (
          !components[componentName].hasOwnProperty('hideOnValidationType') ||
          !components[componentName].hideOnValidationType.includes(type)
        );
      }

      return true;
    },
  },
};
</script>

