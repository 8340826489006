<template>
  <div class="terms-and-conditions-view mb-8">
    <v-card class="mt-5 mb-8 pa-4" elevation="2" :loading="loading">
      <v-card-title class="mb-2"
        ><span class="h5">{{ $t('toc.headline') }} </span>
      </v-card-title>
      <v-card-text class="body-1 text--primary toc.label pb-6">
        {{ $t('toc.label') }}
      </v-card-text>
      <template v-if="documents.length > 0">
        <div class="terms-and-condition" v-for="(document, index) in documents" v-bind:key="`document${index}`">
          <!-- GROUPED document list -->
          <template v-if="document.group">
            <div class="pb-8">
              <v-card-text class="subtitle-1 pb-0">{{ $t(document.group.description) }}</v-card-text>
              <span class="subtitle-2 ma-4 pt-0 pb-0 grey-700--text">{{ $t(document.group.title) }}</span>
              <v-row v-for="(term, j) in document.group.documents" v-bind:key="`term${j}`" :class="`term${j} ma-0`">
                <v-col cols="12" class="pb-0">
                  <v-checkbox
                    class="ma-0"
                    v-model="checkboxs[`checkbox-${index}${j}`]"
                    v-if="!term.hide"
                    :id="`checkbox-${index}${j}`"
                    @change="onTermsClick"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <v-card-text
                        @click.stop
                        class="body-2 pl-2 pa-0 ma-0 text-sm-justify"
                        :class="{ 'error--text': !checkboxs[`checkbox-${index}${j}`] && errors.length > 0 }"
                        v-html="
                          $t(term.text, {
                            download: `<a target='_blank' class='primary--text' href='${$t(term.path, {
                              language,
                            })}'
                          >${$t(term.label)}</a>`,
                          })
                        "
                      />
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </div>
          </template>
          <!-- simple document list -->
          <template v-if="document.label">
            <v-checkbox
              class="ma-0"
              v-model="checkboxs[`checkbox-${index}`]"
              v-if="!term.hide"
              :id="`checkbox-${index}`"
              @change="onTermsClick"
              hide-details="auto"
            >
              <template v-slot:label>
                <v-card-text
                  class="body-2 pl-2 pa-0 ma-0"
                  :class="{ 'error--text': !checkboxs[`checkbox-${index}`] && errors.length > 0 }"
                  v-html="
                    $t(document.text, {
                      download: `<a target='_blank' href='${$t(document.path, {
                        language,
                      })}'
                        >${$t(document.label)}</a>`,
                    })
                  "
                />
              </template>
            </v-checkbox>
          </template>
        </div>
      </template>

      <!-- WALLETS MIGRATION WARNING -->
      <template v-if="showMigrationMessage">
        <v-row class="migration-alert body-1 primary--text ma-0 pa-0 align-center">
          <v-col cols="1">
            <v-icon color="primary">info</v-icon>
          </v-col>
          <v-col cols="11">
            {{ $t('toc.migration') }}
          </v-col>
        </v-row>
      </template>

      <!-- ERRROS -->
      <template v-if="errors.length > 0">
        <v-row class="ma-0 pa-0 pt-0 error--text">
          <v-col cols="1">
            <v-icon dark color="error">error</v-icon>
          </v-col>
          <v-col cols="11">
            <template class="mt-4" v-for="error in errors">
              <p class="ma-0" v-bind:key="error">{{ error }}</p>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <!-- BACK / CONTINUE -->
    <v-row class="ma-0">
      <BrandedButton
        :title="$t('prevStep')"
        color="primary"
        type="tertiary"
        text
        small
        :disabled="loading"
        :onClick="onBack"
        icon-left="chevron-left"
      />
      <v-spacer></v-spacer>

      <BrandedButton
        :title="$t('nextStep')"
        color="accent"
        type="primary"
        small
        icon-right="chevron-right"
        :onClick="onSave"
        :loading="loading"
      />
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { getCurrentSession } from '@/utils/Auth';
import { mapGetters } from 'vuex';
import BrandedButton from '@/components/reusable/BrandedButton';

export default {
  name: 'TermsAndConditions',
  components: {BrandedButton},
  data() {
    return {
      tocVersion: null,
      documents: [],
      checkboxs: {},
      errors: [],
      loading: false,
      canSubmit: false,
    };
  },
  async mounted() {
    this.$store.commit('setLoaded', { loaded: true });

    const currentSession = getCurrentSession();
    this.loading = true;

    await axios
      .get(`${process.env.VUE_APP_BACKEND_BASE_DOMAIN}/toc?domain=${this.onboardingOrigin}`, {
        headers: {
          Authorization: 'Bearer ' + currentSession.idToken,
        },
      })
      .then((response) => {
        this.tocVersion = response.data.tocVersion;
        this.documents = response.data.documents;
        this.configCheckboxes();
      });

    this.loading = false;
  },
  methods: {
    onSave: function () {
      this.errors = [];
      const currentSession = getCurrentSession();

      if (!this.canSubmit) {
        this.errors = [this.$t('toc.errors.default')];

        return false;
      }

      this.loading = true;

      return axios
        .post(
          `${process.env.VUE_APP_BACKEND_BASE_DOMAIN}/toc`,
          {
            tocVersion: this.tocVersion,
            documents: this.documents,
            domain: this.onboardingOrigin,
          },
          {
            headers: {
              Authorization: 'Bearer ' + currentSession.idToken,
            },
          }
        )
        .then(async () => {
          await this.$store.dispatch('fetchOnboardingStatus');

          return this.onNext();
        })
        .catch((e) => {
          console.log(e);

          return false;
        })
        .finally(async () => {
          this.loading = false;
        });
    },
    onNext() {
      this.$router.push('/onboarding/ident');
    },
    onBack() {
      this.$router.push('/onboarding/taxes');
    },
    configCheckboxes() {
      this.documents.map((terms, termsIndex) => {
        //can be a group
        if (terms.group) {
          terms.group.documents.map((groupedDocument, docIndex) => {
            this.checkboxs[`checkbox-${termsIndex}${docIndex}`] = groupedDocument.hide ? true : this.hasAcceptedTerms;
          });
        } else {
          // or just a document
          this.checkboxs[`checkbox-${termsIndex}`] = this.hasAcceptedTerms;
        }
      });

      // trigger validation
      this.onTermsClick();
    },
    onTermsClick() {
      let canSubmit = true;

      Object.keys(this.checkboxs).forEach((key) => {
        if (this.checkboxs[key] === false) {
          canSubmit = false;
        }
      });

      this.canSubmit = canSubmit;
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters(['getOnboardingComponents', 'onboardingOrigin']),
    language() {
      return this.$i18n.language.split('-')[0].toUpperCase();
    },
    hasAcceptedTerms() {
      return this.getOnboardingComponents.hasAcceptLatestToC.valid;
    },
    showMigrationMessage() {
      let foundMigrationMessage = false;
      this.documents.map((term) => {
        if (term.group && term.group.title.includes('tangany')) {
          term.group.documents.map((document) => {
            if (document.hasOwnProperty('hide') && !document.hide) {
              foundMigrationMessage = true;
            }
          });
        }

        return false;
      });

      return foundMigrationMessage;
    },
  },
};
</script>

<style lang="scss">
</style>

