<template>
  <LayoutOnboarding class="onboarding-view" >
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullpath" />
    </transition>
  </LayoutOnboarding>
</template>

<script>
import LayoutOnboarding from '@/views/LayoutOnboarding.vue';

export default {
  name: 'onboarding-view',
  components: {
    LayoutOnboarding,
  },
};
</script>

<style scoped lang="scss">
@import '@/style/media.scss';

.onboarding-view{
  max-width: 700px !important;
}
</style>

