<template>
  <div class="identification-view mb-8">
    <v-card class="mt-5 mb-8 pa-4" :loading="loading || addressLoading">
      <!-- LOADING STATE -->
      <template v-if="loading && currentStep === IDENT_STEPS.START">
        <v-card-title class="mb-2">
          <h5 class="h5">{{ $t('identification.title') }}</h5>
        </v-card-title>
        <v-card-text class="body-1 text--primary pb-6 description">
          {{ $t('identification.step.start.description') }}
        </v-card-text>
        <v-card-text class="text-center">
          {{ $t('identification.loadingMsg') }}
          <v-progress-circular indeterminate color="primary" size="20" width="2" class="mr-2" />
        </v-card-text>
      </template>

      <!-- NOT LOADING -->
      <template v-else>
        <!-- COMPLETE PREVIOUS STEPS, not enough data to start ident -->
        <template v-if="!hasEnoughMetadata && !restartIdent">
          <v-card-title class="mb-2">
            <h5 class="h5">{{ $t('identification.title') }}</h5>
          </v-card-title>
          <v-card-text>
            <span class="body-1 text--primary" v-t="'identification.completePreviousSteps'"></span>
          </v-card-text>
        </template>
        <!-- IS IDENTIFIED, nothing else to show -->
        <template v-else-if="isIdentified">
          <v-card-title class="mb-2">
            <h5 class="h5">{{ $t('identification.step.start.title') }}</h5>
          </v-card-title>
          <v-card-text>
            <span class="body-1 text--primary" v-t="'identification.step.start.description'"></span>
          </v-card-text>
          <v-card-text>
            <span class="body-1 text--primary" v-t="'identification.successMsg'"></span>
          </v-card-text>
          <!-- ADDRESS INFORMATION -->
          <template v-if="!hasValidAddress">
            <v-card-text>
              <AddressInformation
                class="pa-0 ma-0"
                :nextBtnClicked="nextBtnClicked"
                @loading-update="(isLoading) => (addressLoading = isLoading)"
                @error-update="(remainingErrors) => (addressErrors = remainingErrors)"
              />
            </v-card-text>
          </template>
        </template>

        <!-- START STEP -->
        <template v-else>
          <v-tabs-items v-model="currentStep" touchless>
            <v-tab-item>
              <v-card-title class="mb-2">
                <h5 class="h5">{{ $t('identification.step.start.title') }}</h5>
              </v-card-title>
              <v-card-text class="body-1 text--primary pb-6 description">
                {{ $t('identification.step.start.description') }}
              </v-card-text>
              <v-card-title class="subtitle-1 pt-0 pb-6 identificationDocument">
                {{ $t('identification.step.start.identificationDocument') }}
              </v-card-title>
              <v-card-text class="body-1 text--primary pb-6 identifyWith">
                {{ $t('identification.step.start.identifyWith') }}
              </v-card-text>
              <v-card-text class="pt-0">
                <v-radio-group class="ma-0 pa-0" v-model="hideAddress" hide-details="auto">
                  <v-radio :value="true" id="with-address-radio" class="pb-1">
                    <template v-slot:label>
                      <span class="body-1 text--primary identifyWithAddress">{{
                        $t('identification.step.start.identifyWithAddress')
                      }}</span>
                    </template>
                  </v-radio>
                  <v-radio :value="false" id="without-address-radio">
                    <template v-slot:label>
                      <span class="body-1 text--primary identifyWithoutAddress">{{
                        $t('identification.step.start.identifyWithoutAddress')
                      }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card-text>

              <!-- ADDRESS INFORMATION -->
              <template v-if="identWithoutAddress || (!hideAddress && !isIdentified && !hasValidAddress)">
                <v-card-text>
                  <AddressInformation
                    class="pa-0 ma-0"
                    :nextBtnClicked="nextBtnClicked"
                    @loading-update="(isLoading) => (addressLoading = isLoading)"
                    @error-update="(remainingErrors) => (addressErrors = remainingErrors)"
                  />
                </v-card-text>
              </template>

              <!-- CONTINUE / RE-IDENTIFY-->
              <v-card-text v-if="!loading">
                <p class="body-1 text--primary reIdentify" v-if="restartIdent" v-t="'identification.reIdentify'"></p>
                <p
                  class="body-1 text--primary ma-0 continueToIdent"
                  v-t="'identification.step.start.continueToIdent'"
                ></p>
                <v-row justify="end">
                  <v-spacer />
                  <BrandedButton
                    :title="restartIdent ? $t('identification.reIdentifyButton') : $t('nextStep')"
                    type="secondary"
                    iconRight="chevron-right"
                    color="primary"
                    class="mr-3 mt-10"
                    :loading="loading || addressLoading"
                    :onClick="onContinue"
                    :disabled="
                      loading ||
                      addressLoading ||
                      !hasEnoughMetadata ||
                      (!hasValidAddress && !hideAddress) ||
                      isIdentified
                    "
                  />

                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-title class="mb-2">
                <h5 class="h5">{{ $t('identification.step.idnow.title') }}</h5>
              </v-card-title>
              <v-card-text class="body-1 text--primary pb-6 intro" v-html="$t('identification.step.idnow.intro')" />

              <div class="verification-box" :class="brandName.toLowerCase()">
                <v-card-title class="subtitle-1 pt-0 pb-2 identificationDocument">
                  {{ $t('identification.step.idnow.chooseIdent') }}
                </v-card-title>
                <v-tabs v-model="currentTab" @change="onTabChange">
                  <v-tab class="subtitle-1 text--primary elevation-2">{{
                    $t('identification.step.idnow.video.title')
                  }}</v-tab>
                  <v-tab class="subtitle-1 text--white elevation-2">{{
                    $t('identification.step.idnow.autoIdent.title')
                  }}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentTab">
                  <!-- IDNOW VIDEO IDENT TAB -->
                  <v-tab-item>
                    <v-card flat class="identification-view__ident-tab pa-8 pt-6 pb-6">
                      <!-- <v-card-text class="subtitle-2 text--primary pb-0">Waiting time: 14 minutes</v-card-text> -->
                      <v-card-text v-if="loading" class="text-center body-1 text--primary">
                        <v-progress-circular indeterminate color="primary" size="20" width="2" class="mt-2" />
                      </v-card-text>
                      <v-card-text
                        v-else
                        class="body-1 text--primary pb-0 ident-code pa-0"
                        v-html="
                          $t('identification.step.idnow.video.description', {
                            identCode,
                          })
                        "
                      />
                      <v-row align="center" class="pa-0 ma-0 mt-4">
                        <v-col cols="2" sm="1" class="pr-0 pl-0">
                          <img class="logo id-now" src="@/assets/logo/idNow.png" />
                        </v-col>
                        <v-col>
                          <span v-html="$t('identification.step.idnow.install')" />
                        </v-col>
                      </v-row>
                      <v-row align="center" class="download-app pa-0 ma-0">
                        <v-col cols="12" md="6" class="body-1 text--primary pa-0">
                          {{ $t('identification.step.idnow.download') }}
                        </v-col>
                        <v-col cols="5" md="3" class="download-link pa-0">
                          <a :href="idnowApps.google" target="_blank">
                            <img class="logo google-play" src="@/assets/logo/GooglePlay.png" />
                          </a>
                        </v-col>
                        <v-col cols="5" md="3" class="download-link pa-0">
                          <a :href="idnowApps.apple" target="_blank">
                            <img class="logo apple-store" src="@/assets/logo/AppStore.png" />
                          </a>
                        </v-col>
                      </v-row>
                      <v-row class="pa-3 pl-0 pr-0 ma-0" v-if="openIdent && openIdent.identCaseUrl">
                        <span
                          class="link body-2 pa-0"
                          v-html="
                            $t('identification.step.idnow.link', {
                              website: `<a target='_blank' href='${webcamUrl}'>
                                ${$t('identification.step.idnow.website')}
                              </a>`,
                            })
                          "
                        />
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <!-- AUTO IDENT TAB -->
                  <v-tab-item>
                    <v-card flat class="identification-view__ident-tab pa-8 pt-6 pb-6">
                      <v-card-text v-if="loading" class="text-center body-1 text--primary pa-0">
                        <v-progress-circular indeterminate color="primary" size="20" width="2" class="mt-2" />
                      </v-card-text>
                      <v-card-text
                        v-else
                        class="body-1 text--primary pb-0 ident-code pa-0"
                        v-html="
                          $t('identification.step.idnow.autoIdent.description', {
                            identCode,
                          })
                        "
                      />
                      <v-row align="center" class="pa-0 ma-0 mt-4">
                        <v-col cols="2" sm="1" class="pr-0 pl-0">
                          <img class="logo id-now" src="@/assets/logo/idNowDark.png" />
                        </v-col>
                        <v-col class="pr-0">
                          <span v-html="$t('identification.step.idnow.autoIdent.install')" />
                        </v-col>
                      </v-row>
                      <v-row align="center" class="pa-0 ma-0">
                        <v-col cols="12" md="6" class="body-1 text--primary pa-0">
                          {{ $t('identification.step.idnow.download') }}
                        </v-col>
                        <v-col cols="5" md="3" class="download-link pa-0">
                          <a :href="autoIdentApps.google" target="_blank">
                            <img class="logo google-play" src="@/assets/logo/GooglePlay.png" />
                          </a>
                        </v-col>
                        <v-col cols="5" md="3" class="download-link pa-0">
                          <a :href="autoIdentApps.apple" target="_blank">
                            <img class="logo apple-store" src="@/assets/logo/AppStore.png" />
                          </a>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <v-card-text
                class="body-1 text--primary"
                v-html="
                  $t('identification.step.idnow.supportMessage', {
                    brandPhoneNumber: `<span class='color-text-primary d-inline-block'>${brandSchema.supportPhone}</span>`,
                  })
                "
              />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </template>
    </v-card>

    <!-- BACK / FINISH ONBOARDING -->
    <v-row class="edit-component__control ma-0 align center space-between">
      <BrandedButton
        :title="$t('prevStep')"
        class="pl-2"
        type="tertiary"
        color="primary"
        text
        small
        :disabled="loading"
        :onClick="onBack"
        iconLeft="chevron-left"
      />
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <BrandedButton
        class="pr-2"
        :title="$t('onboarding_finish')"
        type="primary"
        color="accent"
        small
        :disabled="isDisabled"
        :loading="loading"
        :onClick="finishOnboarding"
        iconRight="chevron-right"
      />

    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { getCurrentSession } from '@/utils/Auth';
import { gtmTrack, gtmActions } from '@/utils/gmt';
import AddressInformation from '@/components/UserMetadata/Address.vue';
import BrandedButton from '@/components/reusable/BrandedButton';

export default {
  name: 'identificationView',
  components: {
    BrandedButton,
    AddressInformation,
  },
  data() {
    const IDENT_STEPS = {
      START: 0,
      VERIFICATION: 1,
    };
    const currentStep = IDENT_STEPS.START;

    return {
      addressLoading: false,
      addressErrors: [],
      currentStep,
      currentTab: null,
      IDENT_STEPS,
      identStatus: null,
      identCases: [],
      loading: false,
      errorMsg: false,
      hideAddress: true,
      wsconnection: null,
      keepWebSocketAlive: false,
      socketReloadAmount: 15,
      nextBtnClicked: false,
      idnowApps: {
        apple: 'https://apps.apple.com/app/idnow-video-ident/id918081242',
        google: 'https://play.google.com/store/apps/details?id=de.idnow',
      },
      autoIdentApps: {
        apple: 'https://apps.apple.com/us/app/idnow-autoident/id1437143249',
        google: 'https://play.google.com/store/apps/details?id=io.idnow.autoident',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getOnboardingComponents',
      'onboardingStatus',
      'getSuccessUrl',
      'brandSchema',
      'validationType',
      'naturalUser',
    ]),
    identWithAddress() {
      return this.hideAddress;
    },
    identWithoutAddress() {
      return !this.identWithAddress;
    },
    hasEnoughMetadata() {
      return (
        this.getOnboardingComponents.hasEnoughMetaData.valid &&
        this.getOnboardingComponents.hasEnoughMetaData.children.taxes.valid &&
        this.getOnboardingComponents.hasAcceptLatestToC.valid
      );
    },
    hasValidAddress() {
      return this.addressErrors.length === 0 && this.onboardingStatus.hasEnoughMetaData.address;
    },
    provider() {
      return this.currentTab !== 0 ? 'AutoIdent' : 'IDNow';
    },
    canCreateIdent() {
      return !this.isIdentified && !this.openIdent;
    },
    openIdent() {
      return this.identCases
        ? this.identCases.find((identCase) => {
            return identCase.provider === this.provider && identCase.identCaseUrl !== null;
          })
        : null;
    },
    identCode() {
      return !!this.openIdent ? this.openIdent.identCaseUrl.split('/').pop() : 'XXX-XXXXXX';
    },
    isIdentified() {
      return this.identStatus ? this.identStatus.identified && this.identStatus.hasValidDocs : false;
    },
    restartIdent() {
      return this.identStatus ? this.identStatus.identified && !this.identStatus.hasValidDocs : false;
    },
    isDisabled() {
      return this.loading || (!this.isIdentified && this.currentStep !== this.IDENT_STEPS.VERIFICATION) || (!this.hasValidAddress && this.isIdentified);
    },
    brandName() {
      return this.brandSchema.name;
    },
    webcamUrl() {
      return this.openIdent.identCaseUrl.replace(
        this.identCode,
        `${this.brandName.toLowerCase()}/identifications/${this.identCode}/webcam`
      );
    },
  },

  async mounted() {
    this.$store.commit('setLoaded', { loaded: true });

    if (!this.hasEnoughMetadata) {
      return;
    }

    await this.loadIdentData();

    if (!this.isIdentified) {
      this.connectWsSockets();
    }

    if (this.isIdentified && !this.onboardingStatus.hasEnoughMetaData.address) {
      this.hideAddress = false;
    }

    this.loading = false;
  },
  beforeDestroy() {
    this.keepWebSocketAlive = false;
    if (this.wsconnection) {
      this.wsconnection.close();
    }
  },
  methods: {
    onContinue() {
      this.currentStep = this.IDENT_STEPS.VERIFICATION;
    },
    onNext() {
      this.trackIdentComplete();
      this.$router.push('/onboarding/final');
    },
    onBack() {
      if (this.currentStep !== this.IDENT_STEPS.START) {
        this.currentStep = this.IDENT_STEPS.START;
      } else {
        this.$router.push('/onboarding/toc');
      }
    },
    connectWsSockets() {
      this.keepWebSocketAlive = true;
      this.socketReloadAmount -= 1;

      if (this.socketReloadAmount <= -1) {
        console.log('reconnect socket max retries reached');

        return;
      }

      const WS_URL = `${process.env.VUE_APP_WEBSOCKET_URL}?idToken=${getCurrentSession().idToken}`;

      this.wsconnection = new WebSocket(WS_URL);

      this.wsconnection.onmessage = (event) => {
        const { action, message } = JSON.parse(event.data);
        console.log('websocket connection onmessage', JSON.parse(event.data));

        if (action === 'messageUser' && message === 'IDENT_RESULT_UPDATED') {
          // listen to ident success event and refresh the page when identification is complete
          this.$router.push('/onboarding');
        }
      };

      this.wsconnection.onopen = (event) => {
        console.log('websocket connection onopen', event);
      };

      this.wsconnection.onclose = (event) => {
        console.log('websocket connection onclose', event);
        if (this.keepWebSocketAlive && event.code === 1001 && event.reason === 'Going away') {
          console.log('reconnecting socket', this.keepWebSocketAlive);
          this.connectWsSockets();
        }

        this.wsconnection = null;
      };

      this.wsconnection.onerror = function (event) {
        console.log('websocket connection onerror', event);
      };
    },
    toggleAddress() {
      this.hideAddress = !this.hideAddress;
    },
    finishOnboarding() {
      if (this.isIdentified && this.hasValidAddress && this.hasEnoughMetadata) {
        this.onNext();
      } else {
        window.location.reload();
      }
    },
    async loadIdentData() {
      this.loading = true;
      try {
        this.identStatus = (await this.fetchIdentStatus()).data;
        this.identCases = (await this.fetchIdentDetails()).data.data;
      } catch (e) {
        console.log('error loadIdentData fetchIdentStatus/fetchIdentDetails', e);
      }
      this.loading = false;

      return this.identStatus;
    },
    fetchIdentStatus() {
      return axios.get(
        process.env.VUE_APP_BACKEND_BASE_DOMAIN + '/onboarding/identStatus' + '?brandName=' + this.brandName,
        {
          headers: {
            Authorization: 'Bearer ' + getCurrentSession().idToken,
          },
        }
      );
    },
    fetchIdentDetails() {
      return axios.get(process.env.VUE_APP_BACKEND_BASE_DOMAIN + '/onboarding/identDetails', {
        headers: {
          Authorization: 'Bearer ' + getCurrentSession().idToken,
        },
      });
    },
    async onTabChange() {
      await this.$nextTick();

      if (this.canCreateIdent) {
        try {
          this.loading = true;
          this.identCases = (await this.createIdentCase()).data.data;

          await this.loadIdentData();

          // listen to ident success event and refresh the page when identification is complete
          this.connectWsSockets();
          this.trackIdentStart();
        } catch (error) {
          console.log('createIdentCase error', error);
          this.errorMsg = error.response.data;
        }
        this.loading = false;
      }

      this.currentStep = this.IDENT_STEPS.VERIFICATION;

      return null;
    },
    createIdentCase() {
      const currentSession = getCurrentSession();

      return axios.post(
        process.env.VUE_APP_BACKEND_BASE_DOMAIN + '/onboarding/identCreate',
        {
          callbackUrl: this.brandSchema.url,
          brandName: this.brandName,
          provider: this.provider,
        },
        {
          headers: {
            Authorization: 'Bearer ' + currentSession.idToken,
          },
        }
      );
    },
    trackIdentStart() {
      // Add tracking action to Google tag Manager dataLayer
      const trackData = { onboardingType: this.validationType };
      gtmTrack(gtmActions.IDENTIFICATION_START, trackData);
    },
    trackIdentComplete() {
      // Add tracking action to Google Tag Manager dataLayer
      const trackData = { onboardingType: this.validationType, onboardingStatus: this.onboardingStatus };
      gtmTrack(gtmActions.ONBOARDING_STATUS, trackData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/media';
@import '@/style/mixins';
@import '@/style/colors';

.identification-view {
  display: block;
  .alert {
    position: relative;
  }

  ::v-deep .v-window {
    overflow: visible;
  }
  &__ident-tab{
    @include media('<=sm'){
      padding:16px !important;
    }
  }
  .edit-component__control {
    align-items: center;
    justify-content: space-between;

    @include media('<=sm') {
      justify-content: center;
    }
  }

  .verification-box {
    &.exporo {
      background-color: color('grey', 'grey-400');
    }
    &.propvest {
      background-color: color('primary', 'primary-2');
    }

    @include media('<=sm') {
      .download-app {
        .pt-0 {
          margin-right: 0;
          padding-right: 0;
          &.download-link {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    padding: 16px;
    margin: 0 -16px 0 -16px;

    ::v-deep {
      .v-window {
        min-height: 240px;
      }

      .ident-code span {
        color: color('text', 'primary');
      }

      img.logo {
        width: 100%;
        height: auto;
        display: flex;
      }

      .v-tab {
        background: color('primary', 'primary-3');
        color: color('text', 'light') !important;
        text-transform: inherit;
        margin: 0 24px 0 0;
        padding: 8px 32px;
        height: 32px;
        align-self: end;
        @include media('<=sm') {
          margin: 0;
        }
      }

      .v-tab.v-tab--active {
        color: color('text', 'primary') !important;
        background: white;
        height: 40px;
      }

      .v-tabs-bar {
        background: none;
      }

      .v-tabs-slider {
        display: none;
      }
    }
  }
}
</style>


