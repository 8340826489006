/**
 * replicate BE service BirthValidationRules 'backend/application/domain/userMetaData/type.js'
 * 
 * @returns translated error message or empty string if date of birth is valid
 */

import Vue from 'vue';

export default function dobValidation (dateOfBirth) {
    if (!dateOfBirth || dateOfBirth.length < 10) {
      return '';
    }
    const { $i18n } = Vue.prototype;

    const maxAge = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 18); // 18 years
    const minAge = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 130); // 130 years;

    if (new Date(dateOfBirth) > new Date()) {
      return $i18n.t('metaData.fields.naturalUser.birth.dateOfBirth.error.invalid');
    }

    if (new Date(dateOfBirth) > maxAge) {
      return $i18n.t('metaData.fields.naturalUser.birth.dateOfBirth.error.belowEighteen');
    }

    if (new Date(dateOfBirth) < minAge) {
      return $i18n.t('metaData.fields.naturalUser.birth.dateOfBirth.error.tooHigh');
    }

    return '';
  }