



































































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { gtmTrack, gtmActions } from '@/utils/gmt';
import { TBrandItem } from '@/utils/brand-schemas';
import { brandNames } from '@/enum/brands';
import FinalIconPropvest from '@/components/reusable/icons/FinalProp.vue';
import FinalIconExporo from '@/components/reusable/icons/FinalExp.vue';
import BrandedButton from '@/components/reusable/BrandedButton.vue';

@Component({
  name: 'final-view',
  computed: {
    ...mapGetters(['brandSchema', 'onboardingStatus', 'naturalUser', 'validationType', 'getSuccessUrl']),
  },
  components: {
    BrandedButton,
    FinalIconPropvest,
    FinalIconExporo,
  },
})
export default class FinalView extends Vue {
  private onboardingStatus: any;
  private validationType: any;
  private getSuccessUrl: any;
  private brandSchema!: TBrandItem;

  public async mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  }
  trackFinal() {
    // Add tracking action to Google Tag Manager dataLayer
    const trackData = { onboardingType: this.validationType, onboardingStatus: this.onboardingStatus };
    gtmTrack(gtmActions.ONBOARDING_STATUS, trackData);
  }

  get brandName(): string {
    return this.brandSchema.name;
  }

  get redirectURL(): string {
    return this.getSuccessUrl ? this.getSuccessUrl : this.brandSchema.url;
  }

  get pendingSuportValidation(): boolean {
    return !this.onboardingStatus.isPEPVerified;
  }

  get isPropvest() {
    return this.brandSchema.name === brandNames.PROPVEST;
  }
}
