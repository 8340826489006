<template>
  <EditComponent
    class="address-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.address.label')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
    @error-update="onErrorUpdate"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <InputComponent
          v-model="naturalUser.address.street"
          :readonly="readonly"
          :label="$t('metaData.fields.naturalUser.address.street.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.street.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.address.street')
              ? niceErrorMessage(errors.naturalUser.address.street)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.address.streetNo"
          :label="$t('metaData.fields.naturalUser.address.streetNo.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.streetNo.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.address.streetNo')
              ? niceErrorMessage(errors.naturalUser.address.streetNo)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.address.streetAdditional"
          :label="$t('metaData.fields.naturalUser.address.streetAdditional.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.streetAdditional.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.address.streetAdditional')
              ? niceErrorMessage(errors.naturalUser.address.streetAdditional)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.address.postalCode"
          :label="$t('metaData.fields.naturalUser.address.postalCode.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.postalCode.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.address.postalCode')
              ? niceErrorMessage(errors.naturalUser.address.postalCode)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.address.city"
          :label="$t('metaData.fields.naturalUser.address.city.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.city.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.address.city') ? niceErrorMessage(errors.naturalUser.address.city) : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <AutoCompleteComponent
          :readonly="readonly"
          v-model="naturalUser.address.country"
          :label="$t('metaData.fields.naturalUser.address.country.label')"
          :placeholder="$t('metaData.fields.naturalUser.address.country.label')"
          :optionsList="countryOptions"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.address.country')
              ? niceErrorMessage(errors.naturalUser.address.country)
              : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="code"
          valueLabel="country"
        />
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import { countryOptions } from '@/enum/countries';

export default {
  name: 'AddressInformation',
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  data() {
    return {
      countryOptions: countryOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
    };
  },
  methods: {
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
    onErrorUpdate(remainingErrors) {
      this.$emit('error-update', remainingErrors);
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'address',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditComponent,
    InputComponent,
    AutoCompleteComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

